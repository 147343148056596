const languageComponent = {
    en:
      {
        home:"Home",
        DS:"Disease database", 
        TP:"Treatment Prevention",
        FAQ:"FAQs",
        Tut:'Tutorial',
        AB:"About Us",
        Con:"Contact Us",
        DP:"For Disease prediction",
        AC:"Advanced Crop Disease Identification",
        sel:"Select crop for disease detection",
        phno:"Enter phone number to continue",
        p1:"Rice",
        p2:"Wheat",
        p3:"Chilli",
        p4:"Tobacco",
        p5:"Corn",
        p6:"Millet",
        p7:"Insect",
        tag:"Care the crop!",
        description:"Plant disease prediction to help farmers to analyze and take actions according to it.",
        Selection:"Select plant icon to scan diseases",
        yp:"Yield Prediction",
        sf:"Smart Farming",
        cf:"Contract Farming",
        fc:"Farming Consultation",
        on_pic:"Modernizing agriculture through AI",
        more:"Please reach us for more information",
        team:"A Crop disease detection team to provide service to the farmers and agricultural organizations",
        Terms:"Terms of use",
        Cookie:"Cookie statement",
        Privacy: "Privacy statement",
        Dis:"Disclaimer",
        oop:"Oops!!!",
        o1:"Voice assistor is only for native languages",
        ok:"OK",
        o2:"Artmac is working on..",
      }
    ,
    ta:
      {
        home: "முன்னுரை",
        DS: "தரவுத்தொகுப்பு",
        TP:"சிகிச்சை தடுப்பு ",
        FAQ:"அடிக்கடி கேட்கப்படும் கேள்விகள்",
        DP:"தாவர நோய் கண்டறிதலுக்கு",
        AC:"மேம்பட்ட பயிர் நோய் கண்டறிதல்",
        sel:"நோய் கண்டறிதலுக்குப் பயிரைத் தேர்ந்தெடுக்கவும்",
        Tut: 'பயிற்சி பெற',
        AB: "பற்றி",
        Con: "தொடர்பு",
        phno:"தொலைபேசி எண்ணை உள்ளிடவும்",
        p1: "அரிசி",
        p2: "கோதுமை",
        p3: "மிளகாய்",
        p4: "புகையிலை",
        p5: "சோளம்",
        p6: "ரகு",
        p7:"பூச்சி",
        tag: "கவனிக்கவும் பயிர்",
        description: 'நெற்பயிர் நோய் முன்னறிவிப்பு விவசாயிகள் பகுப்பாய்வு செய்து அதற்கேற்ப நடவடிக்கை எடுக்க உதவுகிறது.',
        selection: "நோய்களைக் கண்டறிய தாவரத்தின் படத்தைத் தேர்ந்தெடுங்கள்",
        yp: "பயிர் மகசூல் கணிப்பு",
        sf: "புத்திசாலித்தனமான விவசாயம்",
        cf: "ஒப்பந்த விவசாயம்",
        fc: "விவசாய ஆலோசனை அலுவலகம்",
        on_pic: "நுண்ணறிவு மூலம் செயற்கை நவீனமயமாக்கல் விவசாயம்",
        more: "மேலும் தகவலுக்கு எங்களைத் தொடர்பு கொள்ளுங்கள்",
        team: "விவசாயிகள் மற்றும் விவசாய நிறுவனங்களுக்கு சேவை செய்யும் பயிர் நோய் கண்டறிதல் குழு",
        Terms: "பயன்பாட்டு விதிமுறைகள்",
        Cookies: "குக்கீ அறிக்கை",
        Privacy: "தனியுரிமை அறிக்கை",
        Disclaimer: "விதிமுறைகள்",
        oop: "ஐயோ !!!",
        o1: "குரல் உதவியாளர் அம்மொழிகளுக்கானது மட்டுமே",
    ok: "சரி",
    o2: "AI மாதிரி சமைக்கிறது",
        
      }
    ,
    ml:
      {
        home: "ഫോർവേഡ്",
        DS: "ഡാറ്റാസെറ്റ്",
        TP:"ചികിത്സ തടയൽ",
        FAQ:"പതിവായി ചോദിക്കപ്പെടുന്ന ചോദ്യങ്ങൾ",
        Tut: "പരിശീലിക്കാൻ",
        DP:"ചെടികളുടെ രോഗനിർണയത്തിനായി",
        AC:"വിപുലമായ വിള രോഗനിർണയം",
        sel:"രോഗനിർണയത്തിനായി വിള തിരഞ്ഞെടുക്കുക",
        AB: "വിവരം",
        Con: "സമ്പർക്കം",
        phno:"ഫോൺ നമ്പർ നൽകുക",
        p1: "അരി",
        p2: "ഗോതമ്പ്",
        p3: "മുളക്",
        p4: "പുകയില",
        p5: "ചോളം",
        p6: "രഘു",
        p7:"കീടം",
        tag: "പരിചരിക്കുക വിള!",
        description: 'വരിപ്പനി രോഗം കർഷകർക്ക് അനാലിസിസ് ചെയ്ത് അവരനുസരിച്ച് പ്രവർത്തിക്കാനാകുന്ന സഹായം നൽകുന്നു.',
        selection: "രോഗങ്ങളെ കണ്ടെത്താൻ ഒരു സസ്യത്തിന്റെ ചിത്രം തിരഞ്ഞെടുക്കുക",
        yp: "വിളയെടുപ്പ് കണക്കാക്കൽ",
        sf: "പുത്തൻ കാർഷിക രീതികൾ",
        cf: "ഒപ്പ് ഫാം",
        fc: "കൃഷി ഉപദേശ സ്ഥാപനങ്ങൾ",
        on_pic: "ഇൻ്റലിജൻസ് മുഖേന കൃത്രിമമായി ആധുനികവൽക്കരിക്കുന്ന കൃഷി",
        more: "കൂടുതൽ വിവരങ്ങൾക്ക് ഞങ്ങളെ ബന്ധപ്പെടുക",
        team: "കർഷകരെയും കാർഷിക സംരംഭങ്ങളെയും സേവിക്കുന്ന വിള രോഗനിർണ്ണയ സംഘം",
        Terms: "ഉപയോഗ നിബന്ധനകൾ",
        Cookies: "കുക്കി പ്രസ്താവന",
        Privacy: "സ്വകാര്യതാ പ്രസ്താവന",
        Disclaimer: "നിബന്ധനകൾ",
        oop: "അയ്യോ !!!",
        o1: "വോയ്സ് അസിസ്റ്റന്റ് സ്വദേശി ഭാഷകൾക്കു മാത്രമാണ്",
        ok: "ഓക്കേ",
        o2: "AI മോഡൽ ഭക്ഷണം ഉണ്ടാക്കുന്നു",
        
      }
    ,
    te:
      {
        home: "హోమ్ పేజీ",
        DS: "డేటాసెట్",
        TP:"చికిత్స నివారణ",
        FAQ:"తరచుగా అడిగే ప్రశ్నలు",
        Tut: 'సాధన పొందడానికి', 
        DP:"మొక్కల వ్యాధి నిర్ధారణ కోసం",
        AC:"అధునాతన పంట వ్యాధి నిర్ధారణ",
        sel:"రోగ నిర్ధారణ కోసం పంటను ఎంచుకోండి",
        AB: 'గురించి', 
        Con: "సంప్రదించండి",
        phno:"ఫోన్ నంబర్‌ను నమోదు చేయండి",
        p1:"వరి",
        p2:"గోధుమ",
        p3:"మిరప", 
        p4:" పొగాకు",
        p5:"మొక్కజొన్న",
        p6:"రాగులు",
        p7:"పురుగు ",
        tag:"పంటను జాగ్రత్తగా చూసుకోండి!",
        description: 'వరి మొక్క వ్యాధి అంచనా రైతులకు విశ్లేషించి దాని ప్రకారం చర్యలు తీసుకోవడానికి సహాయపడుతుంది.',
        Selection:"వ్యాధులను గుర్తించడానికి మొక్క యొక్క చిత్రాన్ని ఎంచుకోండి",
        yp:"పంట దిగుబడి అంచనా",
        sf:"స్మార్ట్ వ్యవసాయం",
        cf:"ఒప్పంద వ్యవసాయం ",
        fc:"వ్యవసాయ సలహా కార్యాలయం",
        on_pic:"కృత్రిమ మేధస్సు ద్వారా వ్యవసాయాన్ని ఆధునీకరించడం",
        more:"మరింత సమాచారం కోసం మమ్మల్ని సంప్రదించండి",
        team:"రైతులకు మరియు వ్యవసాయ సంస్థలకు సేవలను అందించే పంట వ్యాధి నిర్ధారణ బృందం",
        Terms:"వినియోగ నిబంధనలు",
        Cookie:"కుకీ ప్రకటన",
        Privacy: "గోప్యతా ప్రకటన ",
        Dis:"నిభంధనలు",
        oop:"అయ్యో !!!",
        o1: "వాయిస్ అసిస్టెంట్ స్థానిక భాషల కోసం మాత్రమే",
ok: "సరే",
o2: "AI మోడల్ వంట చేస్తోంది",

        
        
      }
    ,
    ka: 
      {
        home: "ಮುನ್ನುಡಿ",
        DS: "ಡೇಟಾಸೆಟ್",
        TP:"ಚಿಕಿತ್ಸೆ ತಡೆಗಟ್ಟುವಿಕೆ",
        FAQ:"ಆಗಾಗ್ಗೆ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು",
        Tut: "ಅಭ್ಯಾಸ ಪಡೆಯಲು",
        DP:"ಸಸ್ಯ ರೋಗದ ರೋಗನಿರ್ಣಯಕ್ಕಾಗಿ",
        AC:"ಸುಧಾರಿತ ಬೆಳೆ ರೋಗ ರೋಗನಿರ್ಣಯ",
        sel:"ರೋಗನಿರ್ಣಯಕ್ಕಾಗಿ ಬೆಳೆ ಆಯ್ಕೆಮಾಡಿ",
        AB: "ಬಗ್ಗೆ",
        Con: "ಸಂಪರ್ಕ",
        phno:"ಫೋನ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
        p1: "ಅಕ್ಕಿ",
        p2: "ಗೋಧಿ",
        p3: "ಮೆಣಸಿನಕಾಯಿ",
        p4: "ತಂಬಾಕು",
        p5: "ಮೆಕ್ಕೆಜೋಳ",
        p6: "ರಘು",
        p7:"ಹುಳು",
        tag: "ನೋಡಿಕೊಳ್ಳಿ ಬೆಳೆ",
        description: 'ಭತ್ತದ ಸಸ್ಯ ರೋಗ ಭವಿಷ್ಯವು ರೈತರಿಗೆ ವಿಶ್ಲೇಷಿಸಲು ಮತ್ತು ಅದಕ್ಕೆ ಅನುಗುಣವಾಗಿ ಕ್ರಮ ತೆಗೆದುಕೊಳ್ಳಲು ಸಹಾಯ ಮಾಡುತ್ತದೆ.',
        selection: "ರೋಗಗಳನ್ನು ಪತ್ತೆಹಚ್ಚಲು ಸಸ್ಯದ ಚಿತ್ರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
        yp: "ಬೆಳೆ ಇಳುವರಿ ಮುನ್ಸೂಚನೆ",
        sf: "ಸ್ಮಾರ್ಟ್ ಕೃಷಿ",
        cf: "ಒಪ್ಪಂದದ ಕೃಷಿ",
        fc: "ಕೃಷಿ ಸಲಹಾ ಕಚೇರಿ",
        on_pic: "ಬುದ್ಧಿವಂತಿಕೆಯ ಮೂಲಕ ಕೃತಕ ಆಧುನೀಕರಣ ಕೃಷಿ",
        more: "ಹೆಚ್ಚಿನ ಮಾಹಿತಿಗಾಗಿ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ",
        team: "ರೈತರು ಮತ್ತು ಕೃಷಿ ಉದ್ಯಮಗಳಿಗೆ ಸೇವೆ ಸಲ್ಲಿಸುತ್ತಿರುವ ಬೆಳೆ ರೋಗ ರೋಗನಿರ್ಣಯ ತಂಡ",
        Terms: "ಬಳಕೆಯ ನಿಯಮಗಳು",
        Cookies: "ಕುಕಿ ಹೇಳಿಕೆ",
        Privacy: "ಗೌಪ್ಯತೆ ಹೇಳಿಕೆ",
        Disclaimer: "ನಿಯಮಗಳು",
        oop: "ಅಯ್ಯೋ!!!",
        o1: "ಧ್ವನಿ ಸಹಾಯಕರು ಸ್ಥಳೀಯ ಭಾಷೆಗಳಿಗೆ ಮಾತ್ರ",
        ok: "ಸರಿ",
        o2: "AI ಮಾಡೆಲ್ ಅಡುಗೆ ಮಾಡುತ್ತಿದೆ",
        
      }
    ,
    hi: 
      {
        home:"प्रस्तावना",
        DS: "डेटासेट",
        TP:" उपचार निरोधक",
        FAQ:"अक्सर पूछे जाने वाले प्रश्न ",
        DP:"पादप रोग निदान के लिए",
        AC:"उन्नत फसल रोग निदान",
        sel:"निदान हेतु फसल का चयन करें",
        Tut: "पूरा हो रहा है" ,
        AB: "के बारे में",
        Con: "संपर्क करें", 
        phno:"फ़ोन नंबर दर्ज करें",
        p1:"चावल",
        p2: "गेहूं" ,
        p3:"मिर्च", 
        p4:"तंबाकू",
        p5:"मक्का",
        p6:"रघु",
        p7:"कीट",
        tag:"फसल का ख्याल रखें!",
        description: 'धान के पौधे की बीमारी की भविष्यवाणी से किसानों को विश्लेषण करने और उसके अनुसार कार्रवाई करने में मदद मिलती है।',
        selection: "बीमारियों का पता लगाने के लिए एक पौधे का चित्र चुनें",
        yp:"फसल की उपज का पूर्वानुमान" ,
        sf:"स्मार्ट खेती",
        cf:"अनुबंध खेती",
        fc:"कृषि सलाहकार कार्यालय",
        on_pic:"कृत्रिम बुद्धिमत्ता के माध्यम से कृषि का आधुनिकीकरण",
        more:"अधिक जानकारी के लिए हमसे संपर्क करें" ,
        team:"किसान और कृषि उद्यम टीम-फसल रोग निदान सेवाएं प्रदान करना",
        Terms:"उपयोग की शर्तें",
        Cookies: "कुकी विवरण",
        Privacy: "गोपनीयता विवरण",
        Disclaimer: "शर्तें",
        oop: "अरे !!!",
         o1: "वॉइस असिस्टेंट केवल देशी भाषाओं के लिए है",
    ok: "ओके",
    o2: "AI मॉडल खाना बना रहा है",

      },
      bn:
      {

        home: "প্রকল্প",
        DS: "ডেটাসেট",
        TP:"চিকিৎসা প্রতিরোধ ",
        FAQ:"প্রায়শই জানতে চাওয়া প্রশ্ন ",
        Tut: "অভ্যাস করার জন্য",
        DP:"উদ্ভিদ রোগ নির্ণয়ের জন্য",
        AC:"উন্নত ফসলের রোগ নির্ণয়",
        sel:"রোগ নির্ণয়ের জন্য ফসল নির্বাচন করুন",
        AB: "সম্পর্কে",
        Con: "যোগাযোগ",
        phno:"ফোন নম্বর লিখুন",
        p1: "চাল",
        p2: "গম",
        p3: "মরিচ",
        p4: "তামাক",
        p5: "ভুট্টা",
        p6: "রঘু",
        p7:"পোকা",
        tag: "যত্ন করুন ফসল!",
        description: 'ধান গাছের রোগের পূর্বাভাস কৃষকদেরকে বিশ্লেষণ করতে এবং সেই অনুযায়ী ব্যবস্থা নিতে সাহায্য করে।',
        selection: "রোগ সনাক্ত করতে উদ্ভিদের ছবি নির্বাচন করুন",
        yp: "শস্যের ফলন পূর্বাভাস",
        sf: "স্মার্ট ফার্মিং",
        cf: "কন্ট্রাক্ট ফার্মিং",
        fc: "কৃষি পরামর্শ অফিস",
        on_pic: "বুদ্ধিমত্তার মাধ্যমে কৃষির কৃত্রিম আধুনিকীকরণ",
        more: "আরো তথ্যের জন্য আমাদের সাথে যোগাযোগ করুন",
        team: "শস্য রোগ নির্ণয় দল কৃষক ও কৃষি উদ্যোগে সেবা দিচ্ছে",
        Terms: "ব্যবহারের শর্তাবলী",
        Cookies: "কুকি বিবৃতি",
        Privacy: "গোপনীয়তা বিবৃতি",
        Disclaimer: "শর্তাবলী",
        oop:" ওহো !!!",
        o1: "ভয়েস অ্যাসিস্ট্যান্ট শুধুমাত্র মাতৃভাষাগুলোর জন্যই",
        ok: "ঠিক আছে।",
         o2: "AI মডেল রান্না করছে",
       
      }
    ,
  };
  export default languageComponent;