import './main_2.css';
/*import cropcaremainlogo from "./data/Cropcare_main_logo.png";*/
import React,{Component} from "react";
import rice from "./data/logo/1.png";
import wheat from "./data/logo/6.png";
import chilli from "./data/logo/4.png";
import tobacco from "./data/logo/5.png";
import corn from "./data/logo/2.png";
import millet from "./data/logo/3.png";
import arrow from "./data/arrow_4.png"

/*import { GoogleAuthProvider } from "firebase/auth";
import { getAuth,signInWithRedirect,getRedirectResult,signInWithCredential,signInWithPopup} from "firebase/auth";
import { initializeApp } from 'firebase/app';*/
/*
<div className="headline">
            <div className="headline_part">
                <h1>CropCare</h1>
                <p>{this.props.langdata.description}</p>
                <div className='twobuttons'>
                    <button id="getstart" href={`/workspace/${this.props.lang}/assist${this.props.ass}`}>{this.props.langdata.GS}</button>
                    <div className='tutorial' onClick={this.props.scroller}>
                      <div className='container'><div className="circle"><div className='triangle'></div></div></div>
                      <button id='tutorial'>{this.props.langdata.Tut}</button>
                    </div>
                </div>
            </div>
            <img src={cropcaremainlogo} alt="" width="500px" height="500px"></img>
        </div>
*/
class Main2 extends Component{
  
  
  render(){
    return(
    <div className="main_2">
        <div className='headline'>
          <h1>{this.props.langdata.AC}<br></br><br></br></h1>
          <div>
            <p>{this.props.langdata.sel}</p>
            <img src={arrow} alt='' className='arrow'></img>
          </div>
          
        </div>
        <div className='main-content'>
            <div><a href={`/workspace/${this.props.lang}/assist${this.props.ass}`}><img src={rice} alt='rice '></img></a><h4>{this.props.langdata.p1}</h4></div>
            <div onClick={this.props.hider}><button><img src={wheat} alt='wheat '></img></button><h4>{this.props.langdata.p2}</h4></div>
            <div onClick={this.props.hider}><button><img src={chilli} alt='wheat '></img></button><h4>{this.props.langdata.p3}</h4></div>
            <div onClick={this.props.hider}><button><img src={tobacco} alt='wheat '></img></button><h4>{this.props.langdata.p4}</h4></div>
            <div onClick={this.props.hider}><button><img src={corn} alt='wheat '></img></button><h4>{this.props.langdata.p5}</h4></div>
            <div onClick={this.props.hider}><button><img src={millet} alt='wheat '></img></button><h4>{this.props.langdata.p6}</h4></div>
        </div>
        
        
        
    </div>
    );
  }
}

export default Main2;
