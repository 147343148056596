import './player.css';
import React,{Component} from "react";
import Video from "./data/Demo.mp4";

class Player extends Component{
  player = (event) =>{
    console.log(document.getElementById("video-player"));
    var video = document.getElementById('video-player');
    if (video.paused) {
        video.play();
        event.target.textContent = 'Pause';
    } else {
        video.pause();
        event.target.textContent = 'Play';
    }
  }
  render(){
    return(
    <div id='video'>
        <iframe src="https://www.youtube.com/embed/BEfi4_LPg3A" 
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen>
        </iframe>
    </div>
    );
  }
}

export default Player;
