import './App.css';
import React,{Component} from "react";
import Header from './header';
import Page1 from "./page1";
import languageComponent from "./lang";
import tam from "./data/tamil/Ta1.mp3"
import tam1 from "./data/tamil/Ta2.mp3"
import mal from "./data/malayalam/ma1.mp3"
import mal1 from "./data/malayalam/ma2.mp3"
import tel from "./data/telugu/Te1.mp3"
import tel1 from "./data/telugu/Te2.mp3"
import kan from "./data/kannada/Kn1.mp3"
import kan1 from "./data/kannada/Kn2.mp3"
import hin from "./data/hindi/Hi1.mp3"
import hin1 from "./data/hindi/Hi2.mp3"
import ban from "./data/bangla/Bn1.mp3"
import ban1 from "./data/bangla/Bn2.mp3"
import soundoff from "./data/soundoff.png"
import soundon from "./data/soundon.png"
/*
import bottomplant from "./data/bottom-plants.png";
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider } from "firebase/auth";
import { getAuth,signInWithRedirect,getAdditionalUserInfo} from "firebase/auth";
*/
import axios from 'axios';
class App extends Component{
  constructor(){
    super()
    this.state={
      navbar:false,
      lang:"en",
      langdata:languageComponent.en,
      ass:"OFF",
      elang:true
    }
  }
  /*componentDidMount(){
    axios.get('http://34.131.223.26:5000/userdata')
      .then(response => {
        console.log(response.data); // Store the data in state
      })
      .catch(error => {
        console.error("There was an error making the request!", error);
      });
  }*/


/*
  Signer = async () =>{
    var config = {
      apiKey: "AIzaSyAi_ewRGRa_Bq4l1PuNArLeCMPvHblR66Y",
      authDomain: "cropcare-428104.firebaseapp.com",
    };
    const provider = await new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    initializeApp(config)
    const auth = getAuth();
    const additionaldata = await signInWithRedirect(auth, provider).then((result) =>{
      const additionaldata = getAdditionalUserInfo(result)
      return (additionaldata)
    })
    console.log(additionaldata)
  }*/
  audio = new Audio();
  Scroller = (data) =>{
    data.preventDefault();
    var menu = document.getElementsByClassName("overlay")[0];
    if(window.location.pathname===data.target.href){
      data.preventDefault();
    }
    if(data.target.id==="tutorial"){
      document.getElementById('video').scrollIntoView({ behavior: 'smooth' });
    }
    else if(data.target.id==="home"){
      document.getElementsByClassName('main')[0].scrollIntoView({ behavior: 'smooth' });
      if (menu.classList.contains('is-active')){
        this.Nav()
      }
    }
    else if(data.target.id==="about"){
      document.getElementsByClassName('about')[0].scrollIntoView({ behavior: 'smooth' });
      if (menu.classList.contains('is-active')){
        this.Nav()
      }
    }
    else if(data.target.id==="disp"){
      document.getElementsByClassName('main_2')[0].scrollIntoView({ behavior: 'smooth' });
      if (menu.classList.contains('is-active')){
        this.Nav()
      }
    }
  }
  Nav = (data) =>{
    var menu = document.getElementsByClassName("overlay")[0];
    if (menu.classList.contains('is-active')) {
      menu.classList.remove('is-active');
    } else {
      menu.classList.add('is-active');
    }
  }
  Lang = (data) =>{
    if (data.target.tagName === 'BUTTON') {
      document.getElementsByClassName("container2")[0].style.display="none";
    } 
    this.setState({lang:data.target.value})
    this.setState({langdata:languageComponent[data.target.value]})
    document.getElementById("phonediv").style.display="flex";
    /*if(data.target.value==="en"){
      document.querySelectorAll("#assist-btn").forEach(buttons=>{
        buttons.style.display="none";
      })
    }
    else{
      document.querySelectorAll("#assist-btn").forEach(buttons=>{
        buttons.style.display="block";
      })
      if(this.state.ass==="ON"){
        this.TTS(0)
      }
    }
    */
  }
  Phcollector = (event) =>{
    console.log(document.getElementById("phone").value)
    if (event.key === 'Enter') {
      document.getElementsByClassName("container2")[1].style.display="none";
    }
    else if(event.target.tagName === 'BUTTON'){
      document.getElementsByClassName("container2")[1].style.display="none";
      axios.post("/phno",{ "phdata": document.getElementById("phone").value}).then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error('There was an error making the POST request!', error);
      });
    }
    
  }
  
  /*Handlelang = (data) =>{
    axios.post("/api/lang",{ language: this.state.lang }).then((response) => {
      console.log(response.data);
    })
    .catch((error) => {
      console.error('There was an error making the POST request!', error);
    });
  }*/

  Observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
          if (entry.isIntersecting) {
            if(this.state.lang==="ta"){
                  this.audio.src = tam;
              }
              else if(this.state.lang==="ml"){
                  this.audio.src = mal;
              }
              else if(this.state.lang==="te"){
                this.audio.src = tel;
              }
              else if(this.state.lang==="ka"){
                this.audio.src = kan;
              }
              else if(this.state.lang==="hi"){
                this.audio.src = hin;
              }
              else if(this.state.lang==="bn"){
                this.audio.src = ban;
              }
              this.audio.oncanplaythrough = ()=>{
                console.log('Audio can start playing.');
                this.audio.play();
              };
          }
          else{
            if(this.state.lang==="ta"){
              this.audio.src = tam1;
              }
              else if(this.state.lang==="ml"){
                this.audio.src = mal1;
              }
              else if(this.state.lang==="te"){
                this.audio.src = tel1;
              }
              else if(this.state.lang==="ka"){
                this.audio.src = kan1;
              }
              else if(this.state.lang==="hi"){
                this.audio.src = hin1;
              }
              else if(this.state.lang==="bn"){
                this.audio.src = ban1;
              }
              this.audio.oncanplaythrough = ()=>{
              console.log('Audio can start playing.');
              this.audio.play();
            };
          }
      });
      },{
          threshold: 1.0 // Adjust the threshold as needed
      });
 TTS = (data)=>{
  const targetDiv = document.getElementsByClassName("main")[0];
      if(data===1){
        this.Observer.disconnect()
      }
      else{
        this.Observer.observe(targetDiv)
      }
 }
 
 Hider = (data) =>{
  data.preventDefault();
  const dialog = document.getElementById("cookingdialog");
  if(dialog.style.display!=="block"){
    dialog.style.display="block" ;
  }
  else{
    dialog.style.display="none" ;
  }
 }
  Assistor =(data)=>{
    const dialog = document.getElementById("langdialog")
    console.log(dialog.style.display)
    if(this.state.lang==="en" && dialog.style.display!=="block"){
      dialog.style.display="block" ;
    }
    else if(dialog.style.display==="block"){
      dialog.style.display="none" ;
    }
    else{
      if(this.state.ass==="OFF")
        {
          this.setState({ass:"ON"})
          this.TTS(0)
          document.getElementById("soundicon").src=soundon;
          if(window.innerWidth<900){
            this.Nav()
          }
        }
        else{
          this.setState({ass:"OFF"})
          this.audio.pause()
          document.getElementById("soundicon").src=soundoff;
          this.TTS(1)
          if(window.innerWidth<900){
            this.Nav()
          }
        }
    }
    
  }
  render(){
    return(
      <div className='App'>
        <Header signer = {this.Signer} scroller = {this.Scroller} navvy = {this.Nav} Lang = {this.Lang} langdata = {this.state.langdata} ass={this.state.ass} assistor={this.Assistor} lang = {this.state.lang}></Header>
        <Page1 hider={this.Hider} scroller = {this.Scroller} lang = {this.state.lang} langdata = {this.state.langdata} ass={this.state.ass}></Page1>
        <div className='container2' id='langselector'>
          <div className='langselector' >
          <h3>Select language</h3>
          <div>
          <button value="en" onClick={this.Lang}>English</button>
          <button value="ta" onClick={this.Lang}>தமிழ்</button>
          <button value="ml" onClick={this.Lang}>മലയാളം</button>
          <button value="te" onClick={this.Lang}>తెలుగు</button>
          <button value="ka" onClick={this.Lang}>ಕನ್ನಡ</button>
          <button value="hi" onClick={this.Lang}>हिंदी</button>
          <button value="bn" onClick={this.Lang}>বাংলা</button>
          </div>
          </div>
        </div>
        <div className='container2' id='phonediv'>
        <div className='phone' >
          <h3>{this.state.langdata.phno}</h3>
          <div>
          <input type="number" id="phone" name="phone" placeholder="+91 9876543210" onKeyDown={this.Phcollector}></input>
          <button onClick={this.Phcollector}>Enter</button>
          </div>
          </div>
        </div>
        <div className='overlay'>
          <nav id="overlaybar">
              <button id='home' onClick={this.Scroller}>{this.state.langdata.home}</button>
              <button id='about' onClick={this.Scroller}>{this.state.langdata.AB}</button>
              <a href='https://www.kaggle.com/datasets/dedeikhsandwisaputra/rice-leafs-disease-dataset'><span>{this.state.langdata.DS}</span></a>
              <select id="dropdown" name="selectedOption" onChange={this.Lang} value={this.state.lang}>
                <option value="en">English</option>
                <option value="ta">தமிழ்</option>
                <option value="ml">മലയാളം</option>
                <option value="te">తెలుగు</option>
                <option value="ka">ಕನ್ನಡ</option>
                <option value="hi">हिंदी</option>
                <option value="bn">বাংলা</option>
              </select>
              <button  id='contact' onClick={this.Scroller}>{this.state.langdata.Con}</button>
              <button id='assist-btn' onClick={this.Assistor}><img id='soundicon' src={soundoff} alt='"Sound' width="50px" height="50px"></img></button>
              <button id='closer' onClick={this.Nav}> X</button>
          </nav>
        </div>
        <div className='dialog' id='langdialog'>
            <h3>{this.state.langdata.oop}</h3>
            <ul>
            <li>{this.state.langdata.o1}</li>
            </ul>
            <button onClick={this.Assistor}>{this.state.langdata.ok}</button>
        </div>
        <div className='dialog' id='cookingdialog'>
          <h3>{this.state.langdata.oop}</h3>
          <ul>
            <li>{this.state.langdata.o2}</li>
          </ul>
          <button onClick={this.Hider}>{this.state.langdata.ok}</button>
        </div>
    </div>
    );
  }
}
export default App;
