import './cards.css';
import React,{Component} from "react";


class Cards2 extends Component{
    constructor(){
        super()
        this.state = {
            slideIndex:0
        }
    }
componentDidMount(){
    const boxes = document.querySelectorAll('.box');
    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // Adjust this value to determine how much of the element is in view
    };
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('visible');
                observer.unobserve(entry.target); // Stop observing once the animation has been triggered
            }
        });
    }, observerOptions);
    boxes.forEach(box => {
        observer.observe(box);
    });/*
    <div className='cards-sec'>
                <div id='box1' className="box" ><a href={`/card1/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease1}</h1></a></div>
                <div id='box2' className="box" ><a href={`/card2/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease2}</h1></a></div>
                <div id='box3' className="box" ><a href={`/card3/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease3}</h1></a></div>
                <div id='box4' className="box" ><a href={`/card4/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease4}</h1></a></div>
                <div id='box5' className="box" ><a href={`/card5/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease5}</h1></a></div>
                <div id='box6' className="box" ><a href={`/card6/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease6}</h1></a></div>
                <div id='box7' className="box" ><a href={`/card7/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease7}</h1></a></div>
                <div id='box8' className="box" ><a href={`/card8/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease8}</h1></a></div>
                <div id='box9' className="box" ><a href={`/card9/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease9}</h1></a></div>
                <div id='box10' className="box"><a href={`/card10/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease10}</h1></a></div>
                <div id='box11' className="box"><a href={`/card11/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease11}</h1></a></div>
                <div id='box12' className="box"><a href={`/card12/${this.props.lang}/assist${this.props.ass}`}><img src={paddy} alt='' height="80px" width="80px"></img><h1>{this.props.langdata.disease12}</h1></a></div>
            </div>    
    
    
    
    */
}




  render(){
    return(
    <div className="cards2">
        <div className='headline'>
            <h1>Innovative Agricultural Solutions</h1>
        </div>
       <div className='boxes'>
       <div className='box' onClick={this.props.hider}>
            <a href='/yield'>
                <div>
                    <p className='headline2'><b>{this.props.langdata.fc}</b></p>
                    <p>Expert farming consultation to boost crop yield, sustainability, and profitability.</p>
                </div>
                <div className='cardimgdiv'>
                    <img src="https://storage.googleapis.com/cropcare-media/cards_images/2.png" alt=''></img>
                </div>
                
            </a>
        </div>
        
        
        <div className='box' onClick={this.props.hider}>
        <a href='/smartfarming'>
                <div className='cardimgdiv'>
                    <img src="https://storage.googleapis.com/cropcare-media/cards_images/1.png" alt=''></img>
                </div>
                <div>
                    <p className='headline2'><b>{this.props.langdata.sf}</b></p>
                    <p>Transforming agriculture with data-driven insights, automation, and sustainable practices for smarter, more efficient farming</p>
                </div>
                
            </a>
        </div>
       
        
        <div className='box' onClick={this.props.hider}>
        <a href='/contractfarming'>
        <div>
                    <p className='headline2'><b>{this.props.langdata.cf}</b></p>
                    <p>Providing sustainable contract farming partnerships for guaranteed market access and fair pricing.</p>
                </div>
                <div className='cardimgdiv'>
                    <img src="https://storage.googleapis.com/cropcare-media/cards_images/3.png" alt=''></img>
                </div>
            </a>  
        </div>
       </div>
        
        
      </div>
      
    );
  }
}

export default Cards2;
