import './header.css';
import React,{Component} from "react";
import logo from "./data/logo.png";
import soundoff from "./data/soundoff.png"
class Header extends Component{
componentDidMount() {
    window.addEventListener('scroll', function() {
        const header = document.getElementById('header');
        if (window.scrollY > 50) { // Change this value to whatever scroll distance you want
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    });
    const home = document.getElementById("home");
    const about = document.getElementById("about");
    const contact = document.getElementById("contact");
    home.addEventListener("click",function(event){
      if (window.location.pathname === home.getAttribute('href')) {
          event.preventDefault();
      }      
    });
    about.addEventListener("click",function(event){
        if (window.location.pathname === about.getAttribute('href')) {
            event.preventDefault();
        }      
    });
    contact.addEventListener("click",function(event){
      if (window.location.pathname === about.getAttribute('href')) {
          event.preventDefault();
      }      
  });  
    }
  render(){
    return(
    <header id="header">
        <a href="/">
          <img src={logo} width={130} height={110} alt=''></img>
        </a>
        <div className="hamburger" onClick={this.props.navvy}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
            <a href="/" id='home' onClick={this.props.scroller} className='nav'>{this.props.langdata.home}</a>
            <a href="/about/en" id='about' className='nav'>{this.props.langdata.AB}</a>
            <a href="/" id='Tut' className='nav'>{this.props.langdata.Tut}</a>
            <a href="https://artmacsoft.com/contact-us/" id='contact' className='nav'>{this.props.langdata.Con}</a>
            <select id="dropdown" name="selectedOption" onChange={this.props.Lang} value={this.props.lang} className='nav'>
              <option value="en">English</option>
              <option value="ta">தமிழ்</option>
              <option value="ml">മലയാളം</option>
              <option value="te">తెలుగు</option>
              <option value="ka">ಕನ್ನಡ</option>
              <option value="hi">हिंदी</option>
              <option value="bn">বাংলা</option>
            </select>
            <select className='nav'>
              <option value="#">{this.props.langdata.DS}</option>
              <option value="#">Treatment prevention</option>
              <option value="#">FAQs</option>
            </select>
            
            <button id='assist-btn' onClick={this.props.assistor} className='nav'><img id='soundicon' src={soundoff} alt='"Sound' width="50px" height="50px"></img></button>
    </header>
    );
  }
}
export default Header;
