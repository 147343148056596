import React from "react";
import Main from './main';
import About from './About';
import Player from "./player";
import Contact from "./contact";
import Main2 from "./main_2";
import Cards2 from "./cards_2";
class Page1 extends React.Component{
    constructor(){
        super();
        this.state={
            para:"",
            img:""
          }
    }
    render(){
        return(
            <div>
                <Main hider={this.props.hider} scroller = {this.props.scroller} lang = {this.props.lang} langdata = {this.props.langdata} ass = {this.props.ass}></Main>
                <Main2 hider={this.props.hider} scroller = {this.props.scroller} lang = {this.props.lang} langdata = {this.props.langdata} ass = {this.props.ass}></Main2>
                <About langdata = {this.props.langdata}></About>
                <Cards2 hider={this.props.hider} content ={this.props.content} lang = {this.props.lang} langdata = {this.props.langdata} ass = {this.props.ass}></Cards2>
                {/*<Cards hider={this.props.hider} content ={this.props.content} lang = {this.props.lang} langdata = {this.props.langdata} ass = {this.props.ass}></Cards>
                
                {/*<Player langdata = {this.props.langdata}></Player>*/}
                <Contact langdata = {this.props.langdata}></Contact>
            </div>
        );
    }
}
export default Page1